import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuEnComponent } from './menu-en/menu-en.component';
import { MenuComponent } from './menu/menu.component';
import { MenuBistrotComponent } from './menu-bistrot/menu-bistrot.component'
import { MenuBistrotEnComponent } from './menu-bistrot-en/menu-bistrot-en.component'


const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'menu', 
    pathMatch: 'full' 
  },
  { 
    path: 'menu', 
    component: MenuComponent 
  },
  { 
    path: 'menu/en', 
    component: MenuEnComponent 
  },
  { 
    path: 'menu/bistrot', 
    component: MenuBistrotComponent 
  },
  { 
    path: 'menu/bistrot/en', 
    component: MenuBistrotEnComponent 
  },
  { path: '**', redirectTo: 'menu', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
